<template>
  <div class="filter-page">
    <Header class="mb-12" />
    <Form ref="form" :rows="rows" v-model="detail" />
    <FooterButton
      v-show="hideshow"
      cancelLabel="重置"
      confirmLabel="确认"
      @cancel="reset"
      @confirm="confirm"
    />
  </div>
</template>
<script>
import Header from "@/components/header";
import FooterButton from "@/components/common/button/FooterDoubleButton.vue";
import Form from "@/components/common/form/index.vue";
export default {
  components: {
    FooterButton,
    Header,
    Form,
  },
  data() {
    return {
      hideshow: true,
      docmHeight: document.documentElement.clientHeight,  //默认屏幕高度
      showHeight: document.documentElement.clientHeight,   //实时屏幕高度

      detail: {},
      rows: [
        {
          key: "searchRegion",
          label: "区县",
          type: "picker",
          dictType: "region",
          valueString: "id",
          labelString: "name",
          handleChange: () => {
            this.$refs.form.$refs.kjTowninfo[0].fetchTown(
              this.detail.searchRegion
            );
            this.$refs.form.$refs.kjTowninfo[0].reset();
          },
        },
        {
          key: "kjTowninfo",
          label: "乡镇",
          type: "picker",
          dictType: "town",
          valueString: "id",
          labelString: "name",
        },
        {
          key: "plantCover",
          label: "作物覆盖",
          type: "picker",
          dictType: "plantSelect",
        },
        {
          key: "bussinessManId",
          label: "属地化人员",
          type: "picker",
          gap: true,
          dictType: "salesman",
        },
        { key: "orderNum", label: "订货量", type: "range", unit: "吨" },
        { key: "orderRate", label: "订货率", type: "range", unit: "%" },
        { key: "requirementNum", label: "总需求量", type: "range", unit: "吨" },
        { key: "plantNum", label: "种植亩数", type: "range", unit: "亩" },
      ],
    };
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  mounted() {
    // window.onresize监听页面高度的变化
    window.onresize = ()=>{
      return(()=>{
        this.showHeight = document.body.clientHeight;
      })()
    }
  },
  //监听
  watch:{
    showHeight:function() {
      if(this.docmHeight > this.showHeight){
        this.hideshow=false
      }else{
        this.hideshow=true
      }
    }
  },
  activated() {
    if (!window.localStorage.getItem("shidanli-filter")) {
      this.detail = {};
    }
  },
  methods: {
    reset() {
      this.detail = {};
      // this.$emit("input", {});
    },
    confirm() {
      const {
        searchRegion,
        kjTowninfo,
        // plantCover,
        bussinessManId,
        orderNum,
        orderRate,
        requirementNum,
        plantNum,
      } = this.detail;
      const query = {
        searchRegion,
        kjTowninfo,
        // plantCover,
        bussinessManId,
        plantCover: this.$refs.form.$refs.plantCover[0].pickerLabel,
        orderNum1: orderNum ? orderNum[0] : "",
        orderNum2: orderNum ? orderNum[1] : "",
        orderRate1: orderRate ? orderRate[0] : "",
        orderRate2: orderRate ? orderRate[1] : "",
        requirementNum1: requirementNum ? requirementNum[0] : "",
        requirementNum2: requirementNum ? requirementNum[1] : "",
        plantNum1: plantNum ? plantNum[0] : "",
        plantNum2: plantNum ? plantNum[1] : "",
      };
      // this.$emit("input", query);
      // this.$emit("confirm");
      window.localStorage.setItem(
        "shidanli-filter",
        JSON.stringify(query || {})
      );
      this.$router.push({ path: "/bigGrowers" });
    },
  },
};
</script>
<style lang="scss" scoped>
.filter-page {
  width: 100%;
  min-height: 100vh;
  background-color: #fafbfc;
}
</style>
