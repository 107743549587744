<template>
  <div class="big-growers-add">
    <HeaderNavigation type="book" />
    <Form ref="form" :rows="rows" v-model="detail" />
    <Navigation ref="navigation" :show-navigation="false" />
    <FooterButton :loading="loading" @click="submit">保存</FooterButton>
  </div>
</template>
<script>
// ■ /api/bigfarmers/save_bigFarmersVisit1 缺少订货率、属地业务员 入参(UI 有)
// 订货率只需要根据需求量和订货量计算后显示，不需要往后台传，
// 属地业务员只显示，不能选择，也不需要往后台传
// ■ /api/dist/bigfarmers/update 缺少订货率、属地业务员 入参(UI 有)
// 订货率只需要根据需求量和订货量计算后显示，不需要往后台传，
// 经销商登陆时可以选择属地业务员，字段bussinessManId，属地业务员登陆时只显示，不能选择，也不需要往后台传
import HeaderNavigation from "@/components/header/headerWithNavigation";
import Form from "@/components/common/form/index.vue";
import FooterButton from "@/components/common/button/FooterButton.vue";
import Navigation from "@/components/navigation/index.vue";
import { bigGrowersApi } from "@/utils/api.js";
import { getDealerId, getDealerName,getUserType,getNickName,getUserId } from "@/utils/user.js";
export default {
  components: {
    HeaderNavigation,
    Form,
    FooterButton,
    Navigation,
  },
  data() {
    return {
      loading: false,
      isHideTerminalField: true,
      detail: {
        kjDealerName: getDealerName(),
      },
    };
  },
  computed: {
    rows() {
      return [
        {
          key: "bigfarmersType",
          label: "种植大户类型",
          required: true,
          type: "picker",
          dictType: "种植大户类型",
          gap: true,
          handleChange: (value) => {
            // 重置所有信息
            this.isHideTerminalField = true;
            this.$set(this.detail, "kjTerminalNode", "");
            if (value === "终端商种植大户") {
              this.isHideTerminalField = false;
            }
          },
        },
        {
          key: "bigfarmersPerson",
          label: "联系人",
          required: true,
          type: "input",
        },
        {
          key: "bigfarmersTel",
          label: "手机",
          required: true,
          type: "input",
          inputType: "tel",
        },
        {
          key: "birthday",
          label: "生日",
          type: "date",
        },
        {
          key: "sex",
          label: "性别",
          required: true,
          type: "radio",
          data: [
            { key: "1", value: "男" },
            { key: "0", value: "女" },
          ],
          gap: true,
        },
        {
          key: "kjDealerName",
          label: "所属经销商",
        },
        {
          key: "kjTerminalNode",
          label: "所属终端",
          type: "picker",
          required: true,
          dictType: "terminal",
          hide: this.isHideTerminalField,
        },

        {
          key: "kjProvince",
          label: "所属省",
          type: "picker",
          required: true,
          dictType: "provinceAll",
          valueString: "id",
          labelString: "name",
          handleChange: () => {
            this.$refs.form.$refs.kjCity[0].fetchCityAll(this.detail.kjProvince);
            this.$refs.form.$refs.kjCity[0].reset();
            this.$refs.form.$refs.kjRegion[0].reset();
            this.$refs.form.$refs.kjTowninfo[0].reset();
            this.$refs.form.$refs.kjVillage[0].reset();
          },
        },
        {
          key: "kjCity",
          label: "所属市",
          type: "picker",
          required: true,
          valueString: "id",
          labelString: "name",
          handleChange: () => {
            this.$refs.form.$refs.kjRegion[0].fetchRegionByDealer(this.detail.kjCity);
            this.$refs.form.$refs.kjRegion[0].reset();
            this.$refs.form.$refs.kjTowninfo[0].reset();
            this.$refs.form.$refs.kjVillage[0].reset();
          },
          beforeOpenCheck: () => {
            if (!this.detail.kjProvince) {
              return "请选择省";
            }
          },
        },
          
        {
          key: "kjRegion",
          label: "所属县",
          type: "picker",
          required: true,
          valueString: "id",
          labelString: "name",
          handleChange: () => {
            this.$refs.form.$refs.kjTowninfo[0].fetchTown(this.detail.kjRegion);
            this.$refs.form.$refs.kjTowninfo[0].reset();
            this.$refs.form.$refs.kjVillage[0].reset();
          },
          beforeOpenCheck: () => {
            if (!this.detail.kjCity) {
              return "请选择市";
            }
          },
        },
        {
          key: "kjTowninfo",
          label: "所属乡镇",
          type: "picker",
          required: true,
          valueString: "id",
          labelString: "name",
          handleChange: () => {
            this.$refs.form.$refs.kjVillage[0].fetchVillage(
              this.detail.kjTowninfo
            );
            this.$refs.form.$refs.kjVillage[0].reset();
          },
          beforeOpenCheck: () => {
            if (!this.detail.kjRegion) {
              return "请选择市县";
            }
          },
        },
        {
          key: "kjVillage",
          label: "所属村",
          type: "picker",
          required: true,
          valueString: "id",
          labelString: "name",
          beforeOpenCheck: () => {
            if (!this.detail.kjRegion) {
              return "请选择市县";
            }
            if (!this.detail.kjTowninfo) {
              return "请选择乡镇";
            }
          },
        },
        {
          key: "plantNum",
          label: "种植亩数",
          type: "input",
          required: true,
          inputType: "number",
        },
        {
          key: "plantCover",
          label: "作物覆盖",
          type: "picker",
          required: true,
          gap: true,
          dictType: "plantSelectText",
        },
        {
          key: "brand",
          label: "使用品牌",
          type: "input",
          required: true,
          gap: true,
        },
        {
          key: "requirementNum",
          label: "总需求量",
          type: "input",
          required: true,
          inputType: "number",
        },
        {
          key: "orderNum",
          label: "本品进货量",
          type: "input",
          required: true,
          inputType: "number",
        },
        {
          key: "bussinessManId",
          label: "属地业务员",
          type: getUserType() === "属地业务" ? "" : "picker",
          gap: true,
          directReturn: true,
          dictType: getUserType() === "属地业务" ? "" : "salesman",
        },
      ];
    },
  },
  activated() {
    this.detail = {
      kjDealerName: getDealerName(),
    };
    this.checkBussinessMan();
  },
  methods: {
    checkBussinessMan() {
      if (getUserType() == "属地业务") {
        this.detail.bussinessManId = getNickName();
      }
    },
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      const {
        bigfarmersType,
        bigfarmersPerson,
        bigfarmersTel,
        birthday,
        sex,
        kjProvince,
        kjCity,
        kjRegion,
        kjTowninfo,
        kjVillage,
        plantNum,
        brand,
        requirementNum,
        orderNum,
        kjTerminalNode,
        bussinessManId,
        plantCover,
      } = this.detail;
      try {
        await bigGrowersApi.distSave({
          other: "",
          plantCover: plantCover || "",
          kjTerminalNode: kjTerminalNode || "",
          bussinessManId: (getUserType() == "属地业务" ? getUserId() : bussinessManId) || "",
          bigfarmersType: bigfarmersType || "",
          bigfarmersPerson: bigfarmersPerson || "",
          bigfarmersTel: bigfarmersTel || "",
          birthday: birthday || "",
          sex: sex || "",
          kjProvince: kjProvince || "",
          kjRegion: kjRegion || "",
          kjCity: kjCity || "",
          kjTowninfo: kjTowninfo || "",
          kjVillage: kjVillage || "",
          plantNum: plantNum || "",
          brand: brand || "",
          requirementNum: requirementNum || "",
          orderNum: orderNum || "",
          kjDealer: getDealerId(),
          bigFarmersPosition: this.$refs.navigation.value,
          bigFarmersCoordinate: `${this.$refs.navigation.currentLocation.lng},${this.$refs.navigation.currentLocation.lat}`,
        });
        this.$toast("新增成功");
        this.$router.back();
      } catch (err) {
        this.$toast(err.message);
      }
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.big-growers-add {
  width: 100%;
  min-height: 100vh;
  background-color: #fafbfc;
  padding-bottom: 56px;
  box-sizing: border-box;
}
</style>
